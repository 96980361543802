import { colors, fontSize } from "../../styles/variables";

const footerBlack = "#202020";
const transitionDuration = "0.5s";
const normalProductHeight = "160px";
const largeProductHeight = "180px";
const productSelect = {
  zIndex: 1,
  background: "transparent",
  width: "100%",
  textAlign: "center",
  border: "none",
  color: "white",
  fontSize: "24px",
  position: "relative",
  "-webkit-appearance": "none",
  "&:hover": {
    cursor: "pointer",
  },
};

const productSelectWrapper = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  background: footerBlack,
  color: "white",
  padding: "8px 0",
  position: "relative",
};

const productSelectLabel = {
  margin: 0,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%",
};

const productWrapper = {
  margin: 0,
  listStyle: "none",
  padding: "0 32px",
  width: "100%",
  height: "304px",
};

const productListWrapper = {
  display: "flex",
  justifyContent: "center",
  margin: 0,
  padding: 0,
};

const productImage = (props = {}) => {
  const active = props.activeProduct === props.name;
  const length = active ? largeProductHeight : normalProductHeight;
  return {
    width: "100%",
    maxWidth: length,
    maxHeight: length,
    height: "auto",
    margin: "0 auto",
    display: "block",
    transitionProperty: "width height",
    transitionDuration,
  };
};

const product = (props = {}) => {
  const active = props.activeProduct === props.name;
  return {
    opacity: active ? 1 : 0.5,
    transitionProperty: "opacity",
    transitionDuration,
    "&:hover": {
      cursor: "pointer",
    },
  };
};

const productTitle = (props = {}) => {
  const active = props.activeProduct === props.name;
  const activeAfter = active
    ? {
        left: "25%",
        right: "25%",
      }
    : {};
  return {
    textAlign: "center",
    fontSize: fontSize.xlg,
    marginTop: "16px",
    marginBottom: "32px",
    position: "relative",
    "&:after": {
      content: '""',
      height: "2px",
      backgroundColor: colors.meaterRed,
      display: "block",
      position: "absolute",
      left: "50%",
      right: "50%",
      transitionProperty: "left right",
      transitionDuration,
      ...activeAfter,
    },
  };
};

const productSelectIcon = {
  width: "40px",
  position: "absolute",
  right: "12px",
  top: "50%",
  transform: "translateY(-50%)",
};

const imageContainer = {
  height: "185px"
}

export const styles = {
  productSelect,
  productSelectWrapper,
  productSelectLabel,
  productListWrapper,
  productWrapper,
  productImage,
  productTitle,
  product,
  productSelectIcon,
  imageContainer
};
