import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { influencerContactUs, businessContactUs, wizardPath, faqsPath } from "../config";

import { Container, Col, Row } from "react-bootstrap";
import PhoneSupport from "../common/PhoneSupport";

import influencer from "../assets/influencer.svg";
import business from "../assets/business.svg";
import helperImage from "../assets/helper.svg";

import "./styles.scss";

export const Home = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const goToFAQsPage = () => history.push(faqsPath);

  const Tile = ({ src, alt, heading, path }) => {
    return (
      <Col md={4} xs={6}>
        <div className="tile" onClick={() => history.push(path)}>
          <img className="tile__image" src={src} alt={alt} />
          <h2 className="tile__heading">{heading}</h2>
        </div>
      </Col>
    );
  };

  return (
    <Container className="contact-us">
      <h1 className="contact-us__title">{t("how-can-we-help")}</h1>
      <Row className="contact-us__options">
        <Tile src={helperImage} alt={t("support-wizard-alt")} heading={t("support-wizard")} path={wizardPath} />
        <Tile src={business} alt={t("business-enquiries-alt")} heading={t("business-enquiries")} path={businessContactUs} />
        <Tile src={influencer} alt={t("press-and-influencers-alt")} heading={t("press-and-influencers")} path={influencerContactUs} />
      </Row>
      <Col xs={12}>
        <h3 className="contact-us__subtitle">{t("why-not-check-faqs")}</h3>
        <div className="faqs-title" onClick={goToFAQsPage}>
          {t("faqs")}
        </div>
      </Col>
      {/* <PhoneSupport /> */}
    </Container>
  );
};
