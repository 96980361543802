import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { styles } from "./styles";

const useStyles = createUseStyles(styles);

const Product = ({ name, image, classesOverride }) => {
  const { t } = useTranslation();
  const activeProduct = useSelector((state) => state.faqs.activeProduct);
  const classesDefault = useStyles({ activeProduct, name });
  const classes = classesOverride || classesDefault;
  return (
    <div className={classes.product}>
      <img className={classes.productImage} src={image} alt={t(name)} />
      <h2 className={`${classes.productTitle}`}>{t(name)}</h2>
    </div>
  );
};

export default Product;
