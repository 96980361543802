import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { fontSize, colors, mediaQueries } from "../styles/variables";
import { useMediaQuery } from "react-responsive";

const styles = {
  center: ({ tabletUp }) => ({
    textAlign: "center",
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
  }),
  addressWrapper: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
    textAlign: "center",
    marginBottom: "16px",
    color: colors.buttonGrey,
  }),
  title: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
    margin: "1em 0",
  }),
  addressTitle: {
    margin: 0,
    fontWeight: 500,
  },
  address: {
    fontStyle: "normal",
    lineHeight: 1.5,
  },
  snailMail: {
    fontSize: 18,
    lineHeight: 32,
  },
};

const useStyles = createUseStyles(styles);

export const AltContact = () => {
  const { t } = useTranslation();
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.tablet);
  const classes = useStyles({ tabletUp });

  return (
    <div id="snail-mail" className="py-4">
      <div className="container">
        <div className="row">
          <div className="col" style={{ textAlign: "center", color: colors.buttonGrey }}>
            <h3 className={classes.title}>{t("send-us-letter")}</h3>
          </div>
        </div>
        <div className="row justify-content-md-center text-size-lg">
          <div className={`${classes.addressWrapper} col-12 col-md-6`}>
            <p className={classes.addressTitle}>{t("usa-office")}</p>
            <address className={classes.address}>
              Apption Labs, Inc
              <br />
              3779 Cahuenga Blvd
              <br />
              Studio City, CA 91604
            </address>
          </div>
          <div className={`${classes.addressWrapper} col-12 col-md-6`}>
            <p className={classes.addressTitle}>{t("uk-office")}</p>
            <address className={classes.address}>
              Apption Labs Limited
              <br />
              66 Commercial Square
              <br />
              Leicester, LE2 7SR
            </address>
          </div>
        </div>
      </div>
    </div>
  );
};
